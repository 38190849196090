<template>
  <div class="view_play">
    <div class="material_details">
      <div class="main_part">
        <div class="atlas">
          <div class="tool_bar clearfix">
            <el-button type="danger" size="small" class="caiji">收藏</el-button>
            <div class="likeState" @click="giveALike">
              <i class="iconfont" :class="collectDetails.status ? 'iconaixin-kuai hearts' : 'iconaixin-xian'"></i>
            </div>
          </div>
          <div class="main_image">
            <el-image 
            :src="collectDetails.main && collectDetails.main.url ? collectDetails.main.url : ''"
            :preview-src-list="[collectDetails.main && collectDetails.main.url ? collectDetails.main.url : '']" 
            title="点击进入预览模式">
              <div slot="placeholder" class="image-slot load_tip">
                加载中<span class="dot"><i class="el-icon-loading"></i></span>
              </div>
            </el-image>
          </div>
          <div class="tool_bar_bottom">
            <!-- <el-button icon="el-icon-collection-tag" size="mini"> 
              采集 
              {{ collectDetails.nums && collectDetails.nums[0] ? collectDetails.nums[0].coll_nums : 0 }}
            </el-button> -->
            <el-button class="iconfont iconaixin-kuai" size="mini"> 
              喜欢 
              {{ collectDetails.nums && collectDetails.nums[0] ? collectDetails.nums[0].likes_nums : 0 }}
            </el-button>
            <el-button icon="el-icon-s-comment" size="mini"> 
              评论 
              {{ collectDetails.nums && collectDetails.nums[0] ? collectDetails.nums[0].comm_nums : 0 }}
            </el-button>
          </div>
        </div>
        <div class="more_info">
          <!-- 作者 -->
          <div class="author">
            <div class="author_info">
              <div class="avatar">
                <el-avatar 
                  shape="square" 
                  size="large" 
                  :src="collectDetails.main && collectDetails.main.pic ? collectDetails.main.pic : ''" 
                  :title="collectDetails.main && collectDetails.main.username ? collectDetails.main.username : ''">
                </el-avatar>
              </div>
              <div class="briefly">
                <div class="author_name">{{ collectDetails.main && collectDetails.main.username ? collectDetails.main.username : '用户名隐藏' }}</div>
                <div class="created_time">采集于 {{ collectDetails.main && collectDetails.main.created ? collectDetails.main.created : '时间隐藏'}}</div>
              </div>
            </div>
            <div class="desc">{{collectDetails.main && collectDetails.main.info ? collectDetails.main.info : ''}}</div>
          </div>
          <!-- 评论列表 -->
          <div class="comments" v-if="collectDetails.comm_list && collectDetails.comm_list.length > 0">
            <div class="line" v-for="(item, index) in collectDetails.comm_list" :key="index">
              <div class="line_info">
                <div class="line_avatar">
                  <el-avatar shape="square" size="large" :src="item.pic" :title="item.name"></el-avatar>             
                </div>
                <div class="line_briefly">
                  <div class="line_name">{{item.username}}</div>
                  <div class="line_created">{{item.created}}</div>
                </div>
              </div>
              <div class="comment">{{item.content}}</div>
            </div>
          </div>
          <!-- 发表评论 -->
          <div class="add_comment">
            <div>
              <el-avatar shape="square" size="large" :src="collectDetails.main && collectDetails.main.pic ? collectDetails.main.pic : ''"></el-avatar>
            </div>
            <div class="input">
              <textarea placeholder="添加评论" maxlength="250" v-model="comment"></textarea>
              <el-button size="small" class="add_btn" v-if="comment">添加评论</el-button>
            </div>
          </div>
          <!-- 喜欢列表 -->
          <div class="likes" v-if="collectDetails.like_list && collectDetails.like_list.length > 0">
            <div class="total">{{ collectDetails.nums[0].likes_nums }} 喜欢</div>
            <div class="likes_list">
              <el-avatar shape="square" size="large" :src="item.pic" :title="item.name" v-for="(item, index) in collectDetails.like_list" :key="index"></el-avatar>
            </div>
          </div>
        </div>
      </div>
      <div class="side_part">
        <div class="more_collection">
          <div class="more_collection_h">
            <div class="title_and_collection">
              <div class="title">{{ collectDetails.boardName ? collectDetails.boardName : '未知画板' }}</div>
              <div class="collection">{{ collectDetails.boardCount ? collectDetails.boardCount : 0 }} 素材</div>
            </div>
          </div>
          <div class="author_atlas" ref="author_atlas" v-loading="loading">
            <waterfall class="waterfall" :col="3" :data="collectData" :height="waterFallH" @loadmore="loadmore">
              <div class="item_box" v-for="(item, index) in collectData" :key="index" @click="viewDetails(item.id)">
                <div class="public" v-if="item.url">
                  <div class="img_box">
                    <img class="image" :src="item.url" alt="加载失败">
                  </div>
                </div>
                <div class="private" :style="'background-color:' + bgColor[index%10]" v-else>
                  <i class="iconfont iconsuo"></i>
                </div>
              </div>
            </waterfall>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollectionDetails',
  data() {
    return {
      loading: false,
      collectId: '',
      collectDetails: {},
      comment: '',
      collectData: [],
      collectQuery: {cat_id: '', username: '', ord: 0, title: '', use: 0, page: 1},
      bgColor: ['#8dfff7', '#ecc9c7', '#d1cfc0', '#abf9d0', '#c49ac7', '#93dd9b', '#c49ac7', '#b6e284', '#9adbf9', '#70d2db'],
      waterFallH: '600px',
      preview: false,
    }
  },
  methods: {
    getCollectData() {  // 采集数据
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.collectQuery.cat_id,
          username: this.collectQuery.username,
          ord: this.collectQuery.ord,
          title: this.collectQuery.title,
          use: this.collectQuery.use,
          page: this.collectQuery.page,
        },
      }).then(res => {
        if (res.code == 200) {
          this.collectData = this.collectData.concat(res.data.res ? res.data.res : []);
        }
        this.loading = false;
      }).catch(e => {
        this.loading = false;
      })
    },
    getCollectDetails() {  // 采集详情
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/details',
        method: 'GET',
        params: {
          item_id: this.collectId,
        },
      }).then(res => {
        if (res.code == 200) {
          this.collectDetails = res.data ? res.data : {};
        }
      })
    },
    loadmore() {
      this.loading = true;
      this.collectQuery.page += 1;
      this.getCollectData();
    },
    giveALike(id) { // 点赞

    },
    viewDetails(id) {
      this.collectId = id;
      this.getCollectDetails();
    }
  },
  created() {
    this.getCollectData();
    this.collectId = this.$route.query.pins;
    this.getCollectDetails();
  },
}
</script>

<style lang="scss" scoped>
.view_play {
  width: 100%;
  min-width: 1200px;
  background: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 30px;
  .material_details {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .main_part {
      box-sizing: border-box;
      width: 69%;
      margin-bottom: 16px;
      .atlas {
        box-sizing: border-box;
        width: 100%;
        padding: 16px 20px;
        background: #fff;
        margin-bottom: 20px;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.02), 0 16px 32px 0 rgba(0,0,0,.07);
        .tool_bar {
          margin-bottom: 20px;
          .caiji {
            float: left;
          }
          .likeState {
            float: left;
            height: 32px;
            line-height: 32px;
            padding: 0 15px;
            margin-left: 10px;
            background: #ccc;
            border-radius: 2px;
            cursor: pointer;
            &:active {
              transition: transform .12s ease-in-out;
              transform: scale(0.9, 0.9);
            }
            .hearts {
              color: #d71345;
            }
          }
        }
        .main_image {
          box-sizing: border-box;
          width: 100%;
          text-align: center;
          background: rgba(0,0,0,.12);
          .el-image {
            display: block;
            width: 100%;
            .load_tip,
            .el-image__error {
              height: 400px;
              line-height: 400px;
            }
          }
          .load_tip {
            height: 400px;
            line-height: 400px;
          }
        }
        .tool_bar_bottom {
          margin-top: 20px;
        }
      }
      .more_info {
        box-sizing: border-box;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.02), 0 16px 32px 0 rgba(0,0,0,.07);
        .author {
          padding: 16px 20px;
          border-bottom: 1px solid #f5f2f2;
          .author_info {
            display: flex;
            margin-bottom: 10px;
            .avatar {
              display: flex;
              align-items: center;
              margin-right: 10px;
            }
            .briefly {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .author_name {
                font-weight: 600;
                margin-bottom: 3px;
              }
              .created_time {
                font-size: 14px;
                color: #999;
              }
            }
          }
          .desc {
            margin-bottom: 20px;
          }
        }
        .comments {
          .line {
            padding: 16px 20px;
            border-bottom: 1px solid #f5f2f2;
            .line_info {
              display: flex;
              margin-bottom: 10px;
              .line_avatar {
                display: flex;
                align-items: center;
                margin-right: 10px;
              }
              .line_briefly {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .line_name {
                  margin-bottom: 3px;
                }
                .line_created {
                  font-size: 14px;
                  color: #999;
                }
              }
            }
          }
        }
        .add_comment {
          padding: 16px 20px;
          border-bottom: 1px solid #f5f2f2;
          display: flex;
          .input {
            flex: 1;
            margin-left: 10px;
            textarea {
              box-sizing: border-box;
              width: 100%;
              height: 40px;
              line-height: 1.5;
              min-height: 40px;
              resize: vertical;
              margin-bottom: 10px;
              border: 1px solid #ddd;
              border-radius: 4px;
              outline: none;
              padding: 6px 8px;
              color: #777;
            }
            .add_btn {
              float: right;
            }
          }
        }
        .likes {
          padding: 16px 20px;
          .total {
            font-weight: 600;
            margin-bottom: 10px;
          }
          .likes_list {
            display: flex;
            .el-avatar {
              margin: 0 5px 5px 0;
            }
          }
        }
      }
    }
    .side_part {
      box-sizing: border-box;
      width: 29%;
      height: 100%;
      padding: 16px 20px;
      margin-bottom: 20px;
      background-color: #fff;
      box-shadow: 0 1px 3px 0 rgba(0,0,0,.02), 0 16px 32px 0 rgba(0,0,0,.07);
      .more_collection {
        .more_collection_h {
          display: flex;
          margin-bottom: 10px;
          .title_and_collection {
            .title {
              margin-bottom: 3px;
              color: #444;
              &:hover {
                cursor: pointer;
                color: #0080FF;
                text-decoration: underline;
              }
            }
            .collection {
              color: #D0D0D0;
              font-size: 14px;
            }
          }
        }
        .author_atlas {
          .waterfall {
            &::-webkit-scrollbar {
              width: 8px;
              height: 8px;
              background-color: transparent;
              border-radius: 4px;
            }
            &::-webkit-scrollbar-thumb {
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.3) inset;
              width: 8px;
              min-height: 15px;
              border-radius: 4px;
              background-color: rgba(193, 193, 193, 1);
            }
            .item_box {
              box-sizing: border-box;
              width: 100%;
              padding: 0 3px;
              margin-bottom: 3px;
              .public {
                cursor: pointer;
                .img_box {
                  .image {
                    width: 100%;
                    max-height: 350px;
                    object-fit: cover;
                    transition: transform 360ms;
                    &:hover {
                      transform: scale(1.02, 1.02);
                    }
                  }
                }
              }
              .private {
                width: 100%;
                height: 120px;
                position: relative;
                .iconsuo {
                  font-size: 30px;
                  color: #fff;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>